import {initHandsontable} from '../../../packs/handsontable';
import {loadNumberRenderer, monetaryRenderer, qtyRenderer, roundToThousandthsRenderer} from './hot_renderers';
import ChangesValidator, {initChangesValidator} from './data_capture/changes_validator';
import {newChangesOfTotal} from './calculations';
import HotBase from './hot_base';
import HotCrud from './hot_crud';
import {initPopulateDefaultValues} from './data_capture/populate';
import {initUpdateCells} from './data_capture/update_cells';
import {toastErrorMessage} from '../../../packs/toast';

export default class DataCapture extends HotBase {
  setup({
          data,
          containerId,
          currencies,
          primaryCurrency,
          itemNos,
          validQuantities,
          loadModuleInfo,
          loadModuleId,
          itemDescriptions,
          nonEditable,
          qualifiedTypes
        }) {
    this.hot = initHandsontable({
      containerId: containerId,
      data: data,
      additionalOptions: {
        minSpareRows: 1,
        copyPaste: false,
        fillHandle: false,
        undo: true,
        contextMenu: nonEditable ? [] : ['remove_row'],
        hiddenColumns: {
          columns: [0, 3]
        },
        dropdownMenu: false,
        filters: false
      },
      colHeaders: [
        'ID',
        'Load',
        'Warehouse',
        'Item No',
        'Item Description',
        'Qualified Type',
        'QTY',
        'Rate',
        'Total',
        'Tax',
        'Currency'
      ],
      columns: [
        {
          data: 'id',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'load_module_id',
          readOnly: true,
          className: 'htCenter',
          renderer: loadNumberRenderer(loadModuleInfo)
        },
        {
          data: 'load_warehouse_name',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'name',
          className: 'htCenter',
          type: 'dropdown',
          readOnly: nonEditable,
          strict: false,
          source: itemNos
        },
        {
          data: 'full_name',
          readOnly: nonEditable,
          type: 'dropdown',
          strict: false,
          className: 'htCenter',
          source: itemDescriptions.map(pair => pair[1]),
          validator: (query, callback) => {
            callback(true);
          }
        },
        {
          data: 'description',
          readOnly: nonEditable,
          type: 'dropdown',
          strict: false,
          className: 'htCenter',
          source: qualifiedTypes,
          validator: (query, callback) => {
            callback(true);
          }
        },
        {
          data: 'qty',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: qtyRenderer
        },
        {
          data: 'rate',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: roundToThousandthsRenderer
        },
        {
          data: 'total',
          type: 'numeric',
          editor: false,
          readOnly: true,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'tax_included',
          type: 'checkbox',
          readOnly: nonEditable,
          className: 'htCenter'
        },
        {
          data: 'currency',
          type: 'dropdown',
          readOnly: nonEditable,
          strict: true,
          allowInvalid: false,
          source: currencies,
          className: 'htCenter'
        }
      ]
    });
    this.hot.addHook('beforeChange', (changes, source) => {
      if (source === 'Sync.SetId') return;
      validateChanges(changes);
      populate(changes);
      updateCells(changes);
      changes.push(...newChangesOfTotal(this.hot, changes));
    });
    this.hot.addHook('afterChange', (changes, source) => {
      if (source === 'loadData' || source === 'Sync.SetId') return;

      crud.create(
        this.hot,
        changes,
        loadModuleId,
        'data_capture'
      );
      crud.update(this.hot, changes);
      processSubtotals(changes);

      // Show error if qualified type is empty
      let toProcess = changes.reduce((acc, [row, prop, , newValue]) => {
        acc[row] = acc[row] || {};
        acc[row][prop] = newValue;
        return acc;
      }, {});
      Object.entries(toProcess).forEach(([row, props]) => {
        if (!props.description &&
          !this.hot.getDataAtRowProp(row, 'description')) {
          toastErrorMessage('Qualified Type can not be blank');
        }
      });
    });
    this.hot.addHook('beforeRemoveRow', (_index, _amount, physicalRows) => {
      crud.delete(this.hot, physicalRows);
    });
    this.hot.addHook('afterRemoveRow', () => {
      this.updateSubtotals();
    });

    let crud = new HotCrud();
    let validateChanges = initChangesValidator(this.hot);
    let populate = initPopulateDefaultValues({
      hot: this.hot,
      itemDescriptions: itemDescriptions,
      validQuantities: validQuantities,
      primaryCurrency: primaryCurrency,
      loadModuleInfo: loadModuleInfo,
      loadModuleId: loadModuleId
    });
    let updateCells = initUpdateCells({
      hot: this.hot,
      itemDescriptions: itemDescriptions,
      validQuantities: validQuantities,
    });

    let processSubtotals = changes => {
      let subtotalsRecalculate = changes.some(([, prop]) => {
        return prop === 'total' || prop === 'currency';
      });
      subtotalsRecalculate && this.updateSubtotals();
    };

    super.setup(currencies);
  }
};

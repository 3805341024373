window.commonJS = {
  handleInitializeSelect2(element = $('.select2'), additionalOptions = {}) {
    let selectOptions = {
      placeholder: "Select",
      allowClear: true,
      width: '100%',
      ...additionalOptions
    }

    element.select2(selectOptions)
  },

  initializeSelect2Customization(element, additionalOptions = {}) {
    $(element).select2({
      tags: true,
      placeholder: 'Select an option or type',
      allowClear: false,
      createTag: params => {
        let term = $.trim(params.term);
        if (term === '') return null;

        return {
          id: term,
          text: term,
          newTag: true
        };
      },
      ...additionalOptions
    });
  },

  setSelectedToSelect2(selectElement, value) {
    $(selectElement).val(value).trigger('change');

    if ($(selectElement).find('option[value="' + value + '"]').length === 0) {
      let newOption = new Option(value, value, true, true);
      $(selectElement).append(newOption).trigger('change');
    }
  },

  initializeDatepicker(dateFields, options = {}) {
    dateFields.forEach(dateField => {
      $(`#${dateField}_alternate`).datepicker({
        dateFormat: 'mm-dd-yy',
        altField: `#${dateField}`,
        altFormat: 'yy-mm-dd',
        ...options
      });
    });
  },

  initializeClearDatePicker() {
    $('.wrapper').on('change', '.datepicker', e => {
      if ($(e.currentTarget).val().length > 0) {
        $(e.currentTarget)
          .siblings('.clear-datepicker')
          .removeClass('hidden');
      }
    });

    $('.datepicker-wrapper').on('click', '.clear-datepicker', e => {
      $(e.currentTarget).siblings('.datepicker')
        .datepicker(
          'setDate',
          null
        );
      $(e.currentTarget).addClass('hidden');
    });
  },

  clearDatePicker() {
    $('.wrapper').on('change', '.datepicker', function (e) {
      if ($(this).val().length > 0) {
        $(this).siblings('.clear-datepicker').removeClass('hidden')
      }
    });

    $('.datepicker-wrapper').on('click', '.clear-datepicker', function () {
      $(this).siblings('.datepicker').datepicker('setDate', null)
      $(this).addClass('hidden')
    })

    $('.datepicker').datepicker({
      dateFormat: 'dd-mm-yy'
    })
  },

  handleCollapseLink() {
    $('.collapse-link').on('click', function (e) {
      var id = $(this).attr('id')

      $("." + id).slideToggle(500)

      $(".collapse-link#" + id + " svg").toggleClass('fa-chevron-up fa-chevron-down')
    })
  },

  initializeTimePicker() {
    $('.timepicker').timepicker();
  },

  handleInitializeDatepickerAndTimepicker() {
    $('.timepicker').timepicker()

    $('.datepicker').datepicker({
      todayHighlight: true,
      orientation: 'auto top',
      dateFormat: 'dd-mm-yy'
    })

    $(".datepicker").datepicker("option", "dateFormat", "mm-dd-yy")
  },


  handleInitializeDatepicker() {
    $('.datepicker').datepicker({
      todayHighlight: true,
      orientation: 'auto top',
    });

    $('.datepicker').datepicker('option', 'dateFormat', 'mm-dd-yy');
    $('.datepicker').datepicker('option', 'altFormat', 'dd-mm-yy');
  },

  handleIntegerChange(event) {
    const regex = new RegExp('\\d')

    if (
      (event.key.length > 1) ||
      ((event.key === "-") && (!event.currentTarget.value.length)) ||
      regex.test(event.key)
    ) return
    event.preventDefault()
  },

  handleGetFormData($form, excludeArray = []) {
    let fields = $form.find('select, input')
    let json = {}

    $.each(fields, function(i, field) {
      fieldName = field.name.match(/^[a-zA-Z0-9-_]+/g)[0]

      if (!excludeArray.includes(fieldName)) {
        if (field.type == 'select-multiple') {
          json[fieldName] = $(field).val()
        } else {
          json[field.name] = field.value
        }
      }
    })

    return json
  },

  initializeDateRangePicker(elements, searchParam = 'q') {
    elements.forEach(el => {
      let dateRangeEl = $(`#${el}_range`);
      let viewEl = $(`#${el}_range span`);
      let gteqEl = $(`#${searchParam}_${el}_gteq`);
      let lteqEl = $(`#${searchParam}_${el}_lteq`);

      dateRangeEl.daterangepicker({
        ranges: {
          'None': [
            moment().subtract(1, 'days'),
            moment().add(1, 'days')
          ],
          'Today': [
            moment(),
            moment()
          ],
          'Yesterday': [
            moment().subtract(1, 'days'),
            moment().subtract(1, 'days')
          ],
          'Last 7 Days': [
            moment().subtract(6, 'days'),
            moment()
          ],
          'Last 30 Days': [
            moment().subtract(29, 'days'),
            moment()
          ],
          'This Month': [
            moment().startOf('month'),
            moment().endOf('month')
          ],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
          ]
        }
      }, (start, end) => {
        if (start != null && end != null && start._isValid && end._isValid) {
          viewEl.html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
          gteqEl.val(start.format('MMMM D, YYYY'));
          lteqEl.val(end.format('MMMM D, YYYY'));
        } else {
          viewEl.html(null);
          gteqEl.val(null);
          lteqEl.val(null);
        }
      });
      dateRangeEl.on('apply.daterangepicker', (_ev, picker) => {
        switch(picker.chosenLabel) {
          case 'None':
            viewEl.html(null);
            gteqEl.val(null);
            lteqEl.val(null);
            break;
          case 'Today':
            viewEl.html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`);
            gteqEl.val(moment().format('MMMM D, YYYY'));
            lteqEl.val(moment().format('MMMM D, YYYY'));
            break;
        }
      });
    });
  },
}

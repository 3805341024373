import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';

$(document).on('turbolinks:load', () => {
  if ($('.pickup-request-form').length > 0) {
    window.commonJS.initializeDatepicker(
      [
        'pickup_request_created_on'
      ]
    );
    window.commonJS.initializeDatepicker(
      [
        'pickup_request_pickup_on'
      ],
      {
        minDate: 0
      }
    );
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleInitializeSelect2(
      $('#pickup_request_status'),
      {allowClear: false}
    );
    initializeTimePicker();
    handlePickupRequestTypeChanged();
    handleInboundCustomerChanged();
    handlePickupRequestStatusChanged();
    disablePickupRequestInputs();
    handlePickupRequestNewLocation();
    handlePickupRequestNewContact();
    handlePickupRequestSaveButtons();
    handlePickupRequestOnSave();
    handlePickupRequestOnSubmit();
  }
});

let savePickupRequest = (pickupRequestProps = {}) => {
  let $form = $('.pickup-request-form');
  let formData = $form.serializeArray();
  let jsonData = {pickup_request: {}};
  formData.forEach(({name, value}) => {
    let keyParts = name.split('[').map(part => part.replace(']', ''));
    if (keyParts.length === 2) {
      jsonData.pickup_request[keyParts[1]] = value;
    } else {
      jsonData[keyParts[0]] = value;
    }
  });
  Object.keys(pickupRequestProps).forEach((key) => {
    jsonData.pickup_request[key] = pickupRequestProps[key];
  });
  let actualMethod = $form.find('input[name="_method"]').val() ||
    $form.attr('method');

  $.ajax({
    url: $form.attr('action'),
    type: actualMethod,
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(jsonData),
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    success: response => {
      window.location.href = `/pickup_requests/${response.id}/edit`;
      toastSuccessMessage('Pickup Request has been saved successfully');
    },
    error: response => {
      let messages = JSON.parse(response.responseText).errors;
      toastErrorMessage(messages.join(', '));
    }
  });
};

let handlePickupRequestOnSave = () => {
  $('#save-pickup-request').click(e => {
    e.preventDefault();

    savePickupRequest();
  });
};

let handlePickupRequestOnSubmit = () => {
  let $submitBtn =  $('#submit-pickup-request');
  $submitBtn.click(e => {
    e.preventDefault();

    let message = $submitBtn.data('confirm-message');
    if (!confirm(message)) return;

    savePickupRequest({status: 'submitted'});
  });
};

let handlePickupRequestSaveButtons = () => {
  let $saveBtn = $('#save-pickup-request');
  let $submitBtn = $('#submit-pickup-request');
  let $state = $('#pickup-request-state');
  let persistedStatus = $state.data('status');
  let role = $state.data('role');

  switch (role) {
    case 'admin':
      $saveBtn.removeAttr('hidden');
      break;
    case 'inbound':
      if (persistedStatus === 'new') {
        $saveBtn.removeAttr('hidden');
        $submitBtn.removeAttr('hidden');
      }
      break;
  }
};

let clearContactInputs = () => {
  clearInputs([
    '#contact_inbound_customer_name',
    '#contact_inbound_customer_title',
    '#contact_inbound_customer_phone',
    '#contact_inbound_customer_email'
  ]);
};

let clearLocationInputs = () => {
  clearInputs([
    '#address_detail_pickup_address_id',
    '#address_detail_street',
    '#address_detail_postal_code',
    '#address_detail_zone'
  ]);
};

let clearInputs = inputIds => {
  inputIds.forEach(id => $(id).val(''));
};

let handlePickupRequestNewContact = () => {
  let $primary = $('#pickup_request_primary_contact_id');
  let $secondary = $('#pickup_request_secondary_contact_id');
  let $currentEl, selectedPrimaryId, selectedSecondaryId;

  [
    $primary,
    $secondary
  ].forEach($el => {
    $el.on('select2:select', e => {
      let selectedOption = e.params.data;

      if (selectedOption.id !== 'createNewContact') return;

      selectedPrimaryId = $primary.val();
      selectedSecondaryId = $secondary.val();
      $currentEl = $el;

      let $modal = $('#create-new-contact');
      $modal.modal({backdrop: 'static', keyboard: false});
      $('#create-new-contact #inbound-customer-id')
        .val(selectedInboundCustomer());
      $modal.modal('show');
    });
  });

  $('.cancel-create-contact, #create-new-contact .close').click(() => {
    $currentEl.val('').trigger('change.select2');
    clearContactInputs();
  });

  $('#save-contact').click(e => {
    e.preventDefault();

    let $form = $('#new_contact_inbound_customer');

    $.ajax({
      url: '/contact_inbound_customers',
      method: 'POST',
      data: $form.serialize(),
      success: response => {
        clearContactInputs();
        let selectedPrimaryId = $currentEl.is($primary) ? response.id : $primary.val();
        let selectedSecondaryId = $currentEl.is($secondary) ? response.id : $secondary.val();
        $('#create-new-contact').modal('hide');
        refreshContactOptions(selectedPrimaryId, selectedSecondaryId);
      },
      error: response => {
        let messages = JSON.parse(response.responseText).errors;
        toastErrorMessage(messages.join(', '));
      }
    });
  });
};

let handlePickupRequestNewLocation = () => {
  let $select = $('#pickup_request_address_detail_id');

  $select.on('select2:select', e => {
    let selectedOption = e.params.data;

    if (selectedOption.id === 'createNewLocation') {
      let $modal = $('#create-new-location');
      $modal.modal({backdrop: 'static', keyboard: false});
      $('#create-new-location #inbound-customer-id')
        .val(selectedInboundCustomer());
      $modal.modal('show');
    }
  });

  $('.cancel-create-new-location, #create-new-location .close').click(() => {
    $select.val('').trigger('change.select2');
    clearLocationInputs();
  });

  $('#save-location').click(e => {
    e.preventDefault();

    let $form = $('#new_address_detail');

    $.ajax({
      url: '/address_details',
      method: 'POST',
      data: $form.serialize(),
      success: response => {
        refreshLocationOptions(response.id);
        $('#create-new-location').modal('hide');
        clearLocationInputs();
      },
      error: response => {
        let messages = JSON.parse(response.responseText).errors;
        toastErrorMessage(messages.join(', '));
      }
    });
  });
};

let refreshContactOptions = (primarySelectedId, secondarySelectedId) => {
  let customerId = selectedInboundCustomer();
  let $primary = $('#pickup_request_primary_contact_id');
  let $secondary = $('#pickup_request_secondary_contact_id');

  fetchInboundCustomer(customerId)
    .done(data => {
      [
        [$primary, primarySelectedId],
        [$secondary, secondarySelectedId]
      ].forEach(elWithSelected => {
        let $el = elWithSelected[0];
        let id = elWithSelected[1];
        updateContactNameOptions($el, data.contact_inbound_customers);
        $el.val(id).trigger('change.select2');
      });
    })
    .fail(error => {
      console.error('Error:', error);
      toastErrorMessage('Something went wrong. Please try again later.');
    });
};

let initializeTimePicker = () => {
  $('.timepicker').timepicker({
    minuteStep: 5,
    showInputs: true,
    defaultTime: '10:00 AM',
    showMeridian: true,
    showSeconds: false,
    disableMousewheel: false
  });
};

let handlePickupRequestTypeChanged = () => {
  pickupRequestTypeFormSwitch();

  $('.pickup-request-type').change(() => {
    pickupRequestTypeFormSwitch();
  });
};

let handlePickupRequestStatusChanged = () => {
  let $state = $('#pickup-request-state');
  let persistedStatus = $state.data('status');
  if (persistedStatus !== 'closed') return;

  let $statusEl = $('#pickup_request_status');
  let previousStatus = $statusEl.val();

  $statusEl.on('select2:select', e => {
    let selectedOption = e.params.data;
    if (selectedOption.id === 'closed') return;

    const message = 'Changing the status will delete the related Load Module record once you save the form.' +
      ' This action cannot be undone. Do you want to proceed?';

    if (!confirm(message)) setPickupRequestStatus(previousStatus);
  });
};

let setPickupRequestStatus = status => {
  $('#pickup_request_status')
    .val(status)
    .trigger('change.select2');
};

let updateLocationOptions = (addressDetails, selectedId = null) => {
  let selectElement = $('#pickup_request_address_detail_id');
  let emptyOption = new Option(
    '',
    '',
    true,
    true);
  let lastOption = new Option(
    'Create New Location',
    'createNewLocation',
    false,
    false);
  selectElement.empty();
  selectElement.append(emptyOption);

  addressDetails.forEach(address => {
    let option =
      new Option(address.full_address_with_name,
        address.id,
        false,
        false);
    selectElement.append(option);
  });
  selectElement.append(lastOption);
  selectElement.val(selectedId);
  selectElement.trigger('change.select2');
};

let updateContactNameOptions = ($inputEl, contactNames) => {
  let emptyOption = new Option(
    '',
    '',
    true,
    false);
  let lastOption = new Option(
    'Create New Contact',
    'createNewContact',
    false,
    false);
  $inputEl.empty();
  $inputEl.append(emptyOption);

  contactNames.forEach(contact => {
    let displayName = contact.name;
    let option =
      new Option(
        displayName,
        contact.id,
        false,
        false);
    $inputEl.append(option);
  });
  $inputEl.append(lastOption);
  $inputEl.trigger('change.select2');
};

let refreshLocationOptions = addressDetailId => {
  let customerId = selectedInboundCustomer();
  fetchInboundCustomer(customerId)
    .done(data => {
      updateLocationOptions(
        data.address_details,
        addressDetailId
      );
    })
    .fail(error => {
      console.error('Error:', error);
      toastErrorMessage('Something went wrong. Please try again later.');
    });
};

let selectedInboundCustomer = () => {
  return $('#pickup_request_inbound_customer_id').val();
};

let fetchInboundCustomer = customerId => {
  return $.ajax({
    url: `/admin/inbound_customers/${customerId}`,
    type: 'GET',
    dataType: 'json',
    contentType: 'application/json'
  });
};

let handleInboundCustomerChanged = () => {
  let $inboundEl = $('#pickup_request_inbound_customer_id');
  $inboundEl.on('select2:select', e => {
    let selectedOption = e.params.data;
    fetchInboundCustomer(selectedOption.id)
      .done(data => {
        updateLocationOptions(data.address_details);
        [
          $('#pickup_request_primary_contact_id'),
          $('#pickup_request_secondary_contact_id')
        ].forEach($el => {
          updateContactNameOptions($el, data.contact_inbound_customers);
        });
      })
      .fail(error => {
        console.error('Error:', error);
        toastErrorMessage('Something went wrong. Please try again later.');
      });
  });
  $inboundEl.on('select2:clear', _e => {
    [
      $('#pickup_request_address_detail_id'),
      $('#pickup_request_primary_contact_id'),
      $('#pickup_request_secondary_contact_id')
    ].forEach(input => {
      input.empty();
      input.trigger('change.select2');
    });
  });
};

let disableAllInputs = () => {
  $('.pickup-request-form :input')
    .not('[name="_method"]')
    .prop('disabled', true);
  $('.timepicker').addClass('disabled');
};

let disablePickupRequestInputs = () => {
  let $el = $('#pickup-request-state');

  switch ($el.data('status')) {
    case 'closed': {
      disableAllInputs();
      break;
    }
    case 'submitted': {
      if ($el.data('role') === 'inbound') {
        disableAllInputs();
      }
      break;
    }
  }
  $('#pickup_request_status, #save-pickup-request').prop('disabled', false);
};

let pickupRequestTypeFormSwitch = () => {
  switch ($('input[name="pickup_request[request_type]"]:checked').val()) {
    case 'standard':
      showPickupRequestFormBlock($('.standard-form-block'));
      hidePickupRequestFormBlock($('.white-glove-form-block'));
      break;
    case 'white_glove':
      hidePickupRequestFormBlock($('.standard-form-block'));
      showPickupRequestFormBlock($('.white-glove-form-block'));
      break;
    default:
      hidePickupRequestFormBlock($('.standard-form-block'));
      hidePickupRequestFormBlock($('.white-glove-form-block'));
      break;
  }
};

let hidePickupRequestFormBlock = block => {
  block.hide();
  block.find('input').prop('readonly', true);
  block.find('textarea').prop('readonly', true);
};

let showPickupRequestFormBlock = block => {
  block.show();
  block.find('input').prop('readonly', false);
  block.find('textarea').prop('readonly', false);
};

import SectionDataCapture from '../../load_modules/work_order/section_data_capture';
import CurrencyTotal from '../../load_modules/work_order/currency_total';
import SectionService from '../../load_modules/work_order/section_service';
import {toastErrorMessage} from '../../../packs/toast';
import Summary from '../../load_modules/work_order/summary';
import SectionOnsiteService from '../../load_modules/work_order/section_onsite_service';
import SectionDestruction from '../../load_modules/work_order/section_destruction';

export default class LoadWorkOrders {
  constructor() {
    this.sections = [];
    this.summary = new Summary();
  }

  setup(loadModuleIds, nonEditable) {
    loadModuleIds = (loadModuleIds || []).map(id => parseInt(id, 10));

    if (loadModuleIds.length > 0) {
      $('.work-orders-no-items-title').hide();
      $('.work-orders-details').show();

      $.ajax({
        url: '/admin/load_work_orders',
        data: {
          load_module_ids: loadModuleIds
        },
        dataType: 'json'
      })
        .success(data => {
          this.summary.setup(data);
          this.setupSections(data, loadModuleIds, nonEditable);
        })
        .error((_jqXHR, _status, _err) => {
          toastErrorMessage('Something went wrong. Please try again later.');
        });
    } else {
      $('.work-orders-no-items-title').show();
      $('.work-orders-details').hide();
    }
  }

  setupSections(data, loadModuleIds, nonEditable) {
    this.sections = [
      new SectionDataCapture(),
      new SectionOnsiteService(),
      new SectionDestruction(),
      new SectionService()
    ];
    this.currencyTotal = new CurrencyTotal(this.sections);
    this.currencyTotal.setup();
    this.sections.forEach(section => {
      section.setup({load_work_orders: data}, loadModuleIds, nonEditable);
    });
  }

  destroy() {
    this.sections.forEach(section => {
      section.destroy();
    });
    this.summary.destroy();
  }
}

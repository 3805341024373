window.loadDataCapture = {
  init() {
    this.handleInitializeDataTable();
    this.handleInitializeTotalAssetsDataTable();
  },

  handleInitializeDataTable() {
    dataCaptureTable = $('#data-capture').DataTable({
      pageLength: 20,
      stateSave: true,
      paging: true,
      searching: false,
      dom: 'Bfrtip',
      info: false,
      buttons: [],
      serverSide: true,
      ajax: {
        url: `/admin/load_module_data_captures/${$('#load_module_id').val()}`
      },
      columns: [
        { data: 'category' },
        { data: 'description' },
        { data: 'maker' },
        { data: 'model' },
        { data: 'serial_no' },
        { data: 'asset_tag' },
        { data: 'imei' },
        { data: 'hdd_serial' }
      ],
      drawCallback: () => {
        dataCaptureTable
          .buttons()
          .container()
          .appendTo('.button-action');
      }
    });

    dataCaptureTable.on('xhr', (e, settings, json, xhr) => {
      $('.total-assets span:last, .modal-total-assets span:last').text(json.recordsTotal);
    });
  },

  handleInitializeTotalAssetsDataTable() {
    totalAssetsTable = $('#total-assets-table').DataTable({
      stateSave: true,
      paging: false,
      searching: false,
      info: false,
      buttons: [],
      serverSide: true,
      ajax: {
        url: `/admin/load_module_data_captures/${$('#load_module_id').val()}/total_assets`
      },
      columns: [
        { data: 'index' },
        { data: 'category' },
        { data: 'number_of_item' },
      ],
    });
  }
}

import {toastErrorMessage} from '../../packs/toast';
import IdManager from '../common/id_manager';

$(document).on('turbolinks:load', () => {
  if ($('.outbound-logistics-report-index').length > 0) {
    window.commonJS.handleCollapseLink();
    window.commonJS.handleInitializeSelect2();

    window.selectedTransfers = new IdManager();
    initOutboundLogisticsReportDataTable();
    handleFilterOutboundLogisticsReportDataTable();
    handleExportOutboundLogisticsReport();
    handleOutboundDateRangePicker();
    handleOutboundResetFilter();
  }
});

let handleOutboundResetFilter = () => {
  $('#outbound-report-filter-reset').click(_e => {
    window.selectedTransfers.clearIds();
  });
};

let handleOutboundDateRangePicker = () => {
  window.commonJS.initializeDateRangePicker(
    [
      'transferred_on',
      'transfer_items_load_module_date_processed'
    ],
    'transfer_q'
    );
};

let handleFilterOutboundLogisticsReportDataTable = () => {
  $('.submit-outbound-logistics-report-filter').click(e => {
    e.preventDefault();

    window.selectedTransfers.clearIds();

    $('#outbound-logistics-report-table').DataTable().ajax.reload();
  });
};

let handleExportOutboundLogisticsReport = () => {
  const exportUrl = '/reporting/outbound_logistics_reports/export.xlsx';

  $('#outbound-logistics-report-table').on('change', 'input[type="checkbox"]', e => {
    let checkbox = $(e.currentTarget);
    if (checkbox.is(':checked')) {
      window.selectedTransfers.addId(checkbox.val());
    } else {
      window.selectedTransfers.removeId(checkbox.val());
    }
  });

  $('#export-outbound-selected').on('click', e => {
    let selectedIds = window.selectedTransfers.getAllIds();

    if (selectedIds.length > 0) {
      let url = `${exportUrl}?${$.param({transfer_ids: selectedIds})}`;
      $(e.currentTarget).attr('href', url);
    } else {
      toastErrorMessage('No rows selected');
      e.preventDefault();
    }
  });
  $('#export-outbound-all').on('click', e => {
    let data = $('#transfer_search').serializeObject();
    let url = `${exportUrl}?${$.param(data)}`;
    $(e.currentTarget).attr('href', url);
  });
};

let initOutboundLogisticsReportDataTable = () => {
  $('#outbound-logistics-report-table').dataTable({
    searching: false,
    paging: true,
    pageLength: 20,
    info: true,
    orders: [[1, 'desc']],
    order: [],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    columnDefs: [
      {
        orderable: false,
        targets: "_all"
      }
    ],
    ajax: {
      url: '/reporting/outbound_logistics_reports.json'
    },
    preDrawCallback: settings => {
      let api = settings.oInstance.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }
      api.context[0].ajax.data['transfer_q'] = $('#transfer_search').serializeObject()['transfer_q'];
      api.context[0].ajax.data['transfer_q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    rowCallback: (row, data) => {
      if (window.selectedTransfers.hasId(data.id.toString())) {
        $(row).find('.transfer-checkbox').prop('checked', true);
      }
    },
    columns: [
      {
        data: 'id',
        searchable: false,
        render: (id, _type, _row) => {
          return `
            <div class="col">
              <div class="row d-flex flex-row justify-content-center">
                <input
                  type="checkbox"
                  class="row-checkbox transfer-checkbox"
                  value="${id}">
              </div>
            </div>`;
        }
      },
      {
        data: 'code',
        render: (data, _type, _row) => {
          return `
            <a href="${`/admin/transfers/${data.id}`}"
              target="_blank">
              ${data.code}
            </a>`;
        }
      },
      {data: 'inbound_customer_company_name'},
      {data: 'transfer_status_name'},
      {data: 'warehouse_name'},
      {data: 'destination_name'},
      {data: 'transferred_on'},
      {
        data: 'date_processed',
        visible: $('#transfer_items_load_module_date_processed_range').length
      }
    ]
  });
};

let formatOrderDirection = datatableApi => {
  if (datatableApi.order().length === 0) return '';

  let columns = datatableApi.settings().init().columns;
  let order = datatableApi.order()[0];

  return `${columns[order[0]].data}  ${datatableApi.order()[0][1]}`;
};

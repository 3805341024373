import {initHandsontable} from '../../../packs/handsontable';
import HotBase from './hot_base';
import {
  loadNumberRenderer,
  monetaryRenderer,
  onsiteServiceLinkRenderer,
  roundToThousandthsRenderer
} from './hot_renderers';
import HotCrud from './hot_crud';
import {initChangesValidator} from './onsite_service/changes_validator';
import {newChangesOfTotal} from './calculations';

export default class OnsiteService extends HotBase {
  setup({data, containerId, currencies, nonEditable, loadModuleInfo}) {
    this.hot = initHandsontable({
      containerId: containerId,
      data: data,
      additionalOptions: {
        minSpareRows: 0,
        contextMenu: [],
        copyPaste: false,
        fillHandle: false,
        undo: true,
        hiddenColumns: {
          columns: [0]
        },
        dropdownMenu: false,
        filters: false
      },
      colHeaders: [
        'ID',
        'Load',
        'Warehouse',
        'Service',
        'Description',
        'QTY',
        'Rate',
        'Total',
        'Tax',
        'Currency'
      ],
      columns: [
        {
          data: 'id',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'load_module_id',
          readOnly: true,
          className: 'htCenter',
          renderer: loadNumberRenderer(loadModuleInfo)
        },
        {
          data: 'load_warehouse_name',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'name',
          readOnly: true,
          className: 'htCenter',
          renderer: onsiteServiceLinkRenderer
        },
        {
          data: 'description',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'qty',
          type: 'numeric',
          readOnly: true,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'rate',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: roundToThousandthsRenderer
        },
        {
          data: 'total',
          type: 'numeric',
          readOnly: true,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'tax_included',
          type: 'checkbox',
          readOnly: nonEditable,
          className: 'htCenter'
        },
        {
          data: 'currency',
          type: 'dropdown',
          readOnly: nonEditable,
          strict: true,
          allowInvalid: false,
          source: currencies,
          className: 'htCenter'
        }
      ]
    });
    let validateChanges = initChangesValidator(this.hot);
    let crud = new HotCrud();

    this.hot.addHook('beforeChange', (changes, source) => {
      if (source === 'loadData') return;

      validateChanges(changes);
      changes.push(...newChangesOfTotal(this.hot, changes));
    });
    this.hot.addHook('afterChange', (changes, source) => {
      if (source === 'loadData') return;

      crud.update(this.hot, changes);
      processSubtotals(changes);
    });

    let processSubtotals = changes => {
      let subtotalsRecalculate = changes.some(([, prop]) => {
        return prop === 'total' || prop === 'currency';
      });
      subtotalsRecalculate && this.updateSubtotals();
    };

    super.setup(currencies);
  }
}

window.transferIndex = {
  init() {
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleCollapseLink();
    this.handleInitializeTransferDatatable();
    this.handleFilterTransferDataTable();
    window.commonJS.handleInitializeDatepicker();
    $('#transferred_on_alternate').datepicker(
      'option',
      'altField',
      '#transferred_on'
    );
    $('#eta_alternate').datepicker(
      'option',
      'altField',
      '#eta'
    );
    window.commonJS.clearDatePicker();
  },

  handleFilterTransferDataTable() {
    $('.btn-transfer-filter').click(() => {
      $('#transfer-table').DataTable().ajax.reload();
    })
  },

  handleInitializeTransferDatatable() {
    $('#transfer-table').dataTable({
      searching: false,
      paging: true,
      pageLength: 10,
      info: true,
      orders: [[1, 'desc']],
      order: [],
      dom: 'Bfrtip',
      buttons: [],
      serverSide: true,
      ajax: {
        url: window.location.href,
        data: params => {
          params.transfer = window.commonJS
            .handleGetFormData(
              $('#transfer-form'),
              [
                'eta_alternate',
                'transferred_on_alternate'
              ]
            );
        }
      },
      columns: [
        {
          data: 'transfer_code',
          render: (data, _type) => {
            if (data.id) {
              const link = `/admin/transfers/${data.id}`;

              return `<a href="${link}">${data.code}</a>`;
            } else {
              return '';
            }
          }
        },
        { data: 'sales' },
        { data: 'source' },
        { data: 'destination' },
        { data: 'eta' },
        { data: 'transferred_on' },
        { data: 'status' },
        { data: 'warehouse' },
        {
          data: 'destroy',
          className: 'text-center',
          orderable: false,
          render: (data, _type) => {
            if (data) {
              const link = `/admin/transfers/${data}`;

              return `
                <a href="${link}" class="btn code-red btn-xs" data-method="delete" data-confirm="Are you sure?">
                  <i class="fas fa-trash-alt"></i>
                </a>
              `;
            } else {
              return '';
            }
          }
        }
      ]
    })
  }
}

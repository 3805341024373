import {toastErrorMessage} from '../../../../packs/toast';

export let initChangesValidator = hot => {
  return changes => {
    let toSplice = [];
    let errorMessages = new Set();
    let correctionChanges = [];

    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (oldValue === newValue) return toSplice.unshift(index);

      switch (prop) {
        case 'rate':
          if (newValue) {
            newValue = parseFloat(newValue.replace(/,/g, '.')).toFixed(3);

            toSplice.unshift(index);
            correctionChanges.push(
              [row, 'rate', oldValue, newValue]
            );
          }
          break;
        case 'currency':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Currency can not be blank');
          }
          break;
      }
    });

    errorMessages.size > 0 && toastErrorMessage([...errorMessages].join('<br>'));

    toSplice.forEach(index => {
      changes.splice(index, 1);
    });
    correctionChanges.forEach(change => {
      changes.push(change);
    });
  }
};

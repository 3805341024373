import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';

window.inboundCustomerEdit = {
  init() {
    this.handleInboundCustomerStep();
    // Select2 should be initialize after JSstep is initialized
    window.commonJS.handleInitializeSelect2();
    this.handleInboundCustomerDateOfSnow();
    this.handleDisableBillingContact();
    this.handleDifferentBilling();
    this.handleCountryCodeChange();
    this.handleProvinceCodeChange();
    this.handleCheckChange();
    this.handlePrintReport();
    this.handleInitializeCocoon();
    this.handleAddressDetailCountryChange();
    this.handleAddressDetailProvinceChange();
    this.handleRemoveAddressDetail();
    this.handleSelect2Customization();
    this.handleAddCreateOptionToAccountRepSelect2();
    this.handleCreateNewUser();
    this.handleTogglePassword();
  },

  handleTogglePassword() {
    let hidePassword = () => {
      $('#user-password').attr('type', 'password');
      $('.fa-eye').removeClass('hidden');
      $('.fa-eye-slash').addClass('hidden');
    };

    $('.password-toggle-wrapper').click(event => {
      event.preventDefault();

      if ($('#user-password').attr('type') === 'password') {
        $('#user-password').attr('type', 'text');
        $('.fa-eye').addClass('hidden');
        $('.fa-eye-slash').removeClass('hidden');
      } else {
        hidePassword();
      }
    });
    $('#create-new-account-rep').on('hidden.bs.modal', _e => {
      hidePassword();
    });
  },

  handleCreateNewUser() {
    $(document).on('submit', '#create-new-user-form', event => {
      event.preventDefault();

      let form = event.currentTarget;
      if (!form.checkValidity()) {
        form.reportValidity();
        return;
      }

      let $form = $(event.currentTarget);
      let $submitButton = $form.find('input[type="submit"]');
      let formData = $form.serializeArray();
      let jsonData = { user: {} };

      formData.forEach(({ name, value }) => {
        let keyParts = name.split('[').map(part => part.replace(']', ''));
        if (keyParts.length === 2) {
          jsonData.user[keyParts[1]] = value;
        } else {
          jsonData[keyParts[0]] = value;
        }
      });

      $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        data: JSON.stringify(jsonData),
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        success: response => {
          toastSuccessMessage(response.message);

          let $select = $('#inbound_customer_user_id');
          let newOption = new Option(
            `${response.user.first_name} ${response.user.last_name}`,
            response.user.id, false, false);
          $select.find('option:last').before(newOption);
          $select.select2({ placeholder: 'Select', allowClear: true, width: '100%' });
          $select.val(response.user.id).trigger('change');

          $('#create-new-account-rep form')[0].reset();
          $('#create-new-account-rep').modal('hide');
        },
        error: response => {
          let messages = JSON.parse(response.responseText).errors;
          toastErrorMessage(messages.join(', '));
          $submitButton.prop('disabled', false);

        },
        complete: () => {
          $submitButton.prop('disabled', false);
        }
      });
    });
  },

  handleAddCreateOptionToAccountRepSelect2() {
    $('.account-rep-select2').change(e => {
      if (e.target.value === 'createNewAccountRep') {
        $('#create-new-account-rep').modal({ backdrop: 'static', keyboard: false });
        $('#create-new-account-rep #inbound-customer-id').val($('#inbound_customer_user_id').val());
        $('#create-new-account-rep').modal('show');
      }
    });

    $('.cancel-create-account-rep, #create-new-account-rep .close').click(() => {
      $('#inbound_customer_user_id').val('').trigger('change');
    });
  },

  handleSelect2Customization() {
    [
      'country-detail',
      'province-detail',
      'city-detail'
    ].forEach(cls => {
      $.each($(`.${cls}`), (_i, el) => {
        window.commonJS.initializeSelect2Customization(el);
      });
    });

    [
      'country-code',
      'province-code',
      'city-code'
    ].forEach(cls => {
      $.each($(`.${cls}`), (_i, el) => {
        window.commonJS.initializeSelect2Customization(
          el,
          { allowClear: true }
        );
      });
    });
  },

  handleDisableBillingContact() {
    if ($('#is_different_billing').is(':checked')) {
      $('#inbound_customer_bill_contact_name').removeAttr('disabled')
      $('#inbound_customer_bill_contact_title').removeAttr('disabled')
      $('#inbound_customer_bill_contact_phone').removeAttr('disabled')
      $('#inbound_customer_bill_contact_email').removeAttr('disabled')
    } else {
      $('#inbound_customer_bill_contact_name').attr('disabled', true)
      $('#inbound_customer_bill_contact_title').attr('disabled', true)
      $('#inbound_customer_bill_contact_phone').attr('disabled', true)
      $('#inbound_customer_bill_contact_email').attr('disabled', true)
    }
  },

  handleInboundCustomerDateOfSnow() {
    $('#inbound_customer_date_of_snow').datepicker({
      todayHighlight: true,
      orientation: 'auto top',
      dateFormat: 'dd-mm-yy'
    })
  },

  handleInitializeCocoon() {
    $('.add_sub_task a').data('association-insertion-method', 'append').data('association-insertion-node', '.add-address-detail')

    $('.add_sub_contact a').data('association-insertion-method', 'append').data('association-insertion-node', '.add-contact')

    $('.add-address-detail').on('cocoon:after-insert', function (_, row) {
      window.inboundCustomerEdit.handleDisableDeleteAddress();
      window.commonJS.initializeSelect2Customization($(row).find('.select2'));
    }).on('cocoon:after-remove', function () { window.inboundCustomerEdit.handleDisableDeleteAddress() })
  },

  handleDifferentBilling() {
    $('#is_different_billing').on('click', function (e) { window.inboundCustomerEdit.handleDisableBillingContact() })
  },

  handleCountryCodeChange() {
    $('.form-inbound-customer').on('change', '.country-code', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/inbound_customers/get_states",
        data: {
          country: $(this).val(),
          element_id: 'inbound_customer_province_code'
        }
      })
    })
  },

  handleProvinceCodeChange() {
    $('.form-inbound-customer').on('change', '#inbound_customer_province_code', function () {
      let state = $(this).val()
      let country = $('.country-code').val()

      return $.ajax({
        type: "GET",
        url: "/admin/inbound_customers/get_cities",
        data: {
          state: state,
          country: country,
          element_id: 'inbound_customer_city_code',
          id: $('#inbound_customer_id').val()
        }
      })
    })
  },

  handleCheckChange() {
    $('.check').on('change', function () {
      $('.check').prop('checked', false)
      $(this).prop('checked', true)
    })
  },

  handleInboundCustomerStep() {
    $('#form').steps({
      bodyTag: 'fieldset',
      onStepChanging: function (event, currentIndex, newIndex) {
        if (currentIndex > newIndex) { return true }

        let form = $(this)

        if (currentIndex < newIndex) {
          $('.body:eq(' + newIndex + ') label.error', form).remove()
          $('.body:eq(' + newIndex + ') .error', form).removeClass('error')
        }

        form.validate().settings.ignore = ':disabled,:hidden'

        return form.valid()
      },
      onStepChanged: function (event, currentIndex, priorIndex) {
        if (currentIndex === 2 && priorIndex === 3 && priorIndex === 4) { $(this).steps('previous') }

        if (currentIndex === 2) {
          let input = `<li aria-hidden="false"> <a role="menuitem" id="print-inbound-customer">Print</a></li> <li aria-hidden="false"> <a href="/admin/inbound_customers/${id}/download?format=pdf" role="menuitem" id="generate-inbound-customer">Generate</a></li>`

          $('ul[aria-label=Pagination]').prepend(input)
        }

        if (priorIndex === 2 && currentIndex === 1) {
          $('#generate-inbound-customer').parent('li').remove()
          $('#print-inbound-customer').parent('li').remove()
        }

        return true
      },
      onFinishing: function (event, currentIndex) {
        let form = $(this)

        form.validate().settings.ignore = ":disabled"

        return form.valid()
      },
      onFinished: function (event, currentIndex) { $(this).trigger('submit') },
    }).validate({ errorPlacement: function (error, element) { element.after(error) }})
  },

  handlePrintReport() {
    $(".actions").on("click", "#print-inbound-customer", function () {
      window.inboundCustomerEdit.handleGetData()
      window.inboundCustomerEdit.handlePrintDiv('printReport')
    })
  },

  handleGenerateInboundCustomer() {
    $(".actions").on("click", "#generate-inbound-customer", function () {})
  },

  handleGetData() {
    $('.print-customer-name').text($('#inbound_customer_company_name').val())
    $('.print-sap-vendor').text($('#inbound_customer_v_number').val())
    $('.print-date-of-sow').text($('#inbound_customer_date_of_snow').val())
    $('.print-logistic').text($('#inbound_customer_snow_logistics').val())
    $('.print-service-type').text($('#inbound_customer_snow_service_type').val())
    $('.print-medium-code').text($('#inbound_customer_snow_minimum_code_requirements').val())
    $('.print-data-capture').text($('#inbound_customer_snow_data_capture').val())
    $('.print-resale').text($('#inbound_customer_snow_resale').val())
  },

  handleDisableDeleteAddress() {
    // let removeFieldClass = $('.remove_fields')
    // if (removeFieldClass.length === 1)
    //   return removeFieldClass.eq(0).hide()
    // if ($('.nested-fields').eq(0).css('display') === 'none' && removeFieldClass.length === 2)
    //   return removeFieldClass.eq(1).hide()
    // removeFieldClass.eq(0).show()
  },

  handlePrintDiv(divName) {
    let printContents = document.getElementById(divName).innerHTML
    let originalContents = document.body.innerHTML

    document.body.innerHTML = printContents
    window.print()

    document.body.innerHTML = originalContents
    window.inboundCustomerEdit.handlePrintReport()
  },

  handleAddressDetailCountryChange() {
    $('.form-inbound-customer').on('change', '.country-detail', function () {
      let elementId = $(this).parents('.address-detail').find('.province-detail').attr('id')

      return $.ajax({
        type: "GET",
        url: "/admin/inbound_customers/get_states",
        data: {
          country: $(this).val(),
          element_id: elementId
        }
      })
    })
  },

  handleAddressDetailProvinceChange() {
    $('.form-inbound-customer').on('change', '.province-detail', function () {
      let id = $(this).closest('.nested-fields').find('.city-detail').attr('id')
      let state = $(this).val()
      let country = $(this).parents('.address-detail').find('.country-detail').val()

      return $.ajax({
        type: "GET",
        url: "/admin/inbound_customers/get_cities",
        data: {
          state: state,
          country: country,
          element_id: id,
          id: $('#inbound_customer_id').val()
        }
      })
    })
  },

  handleRemoveAddressDetail() {
    $('.pickup-address-details').on('click', '.remove-fields', function(e) {
      let $addressDetail = $(e.target).closest('.form-group')
      $addressDetail.find('.destroy-field').val(true)
      $addressDetail.hide()
    })
  }
}

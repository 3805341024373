$(document).on('turbolinks:load', () => {
  initializePickUpRequestDatatable();
});

let formatOrderDirection = datatableApi => {
  if (datatableApi.order().length === 0) return '';

  let columns = datatableApi.settings().init().columns;
  let order = datatableApi.order()[0];

  return `${columns[order[0]].data}  ${datatableApi.order()[0][1]}`;
};

let initializePickUpRequestDatatable = () => {
  $('#pickup-requests-dt').dataTable({
    searching: false,
    paging: true,
    pageLength: 20,
    info: true,
    orders: [[1, 'desc']],
    order: [],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    ajax: {
      url: window.location.href + '.json'
    },
    preDrawCallback: function (settings) {
      let api = this.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }
      api.context[0].ajax.data['q'] = {};
      api.context[0].ajax.data['q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    columns: [
      {
        data: 'id',
        title: 'Request Number',
        render: (data, _type) => {
          if (!data.id) return '';

          let link = `/pickup_requests/${data.id}/edit`;
          return `<a href="${link}" data-turbolinks="false">${data.uid}</a>`;
        }
      },
      {
        data: 'load_module',
        title: 'Load Number',
        render: (data, _type) => {
          if (!data.id) return '';

          let link = `/admin/load_module_generals/${data.id}`;
          return `<a href="${link}" data-turbolinks="false">${data.load_number}</a>`;
        }
      },
      {data: 'created_on', title: 'Date Created'},
      {data: 'status'},
      {data: 'inbound_customer_company_name'},
      {
        data: 'destroy',
        className: 'text-center',
        orderable: false,
        render: (data, _type) => {
          if (!data) return '';

          let link = `/pickup_requests/${data}`;

          return `
            <a href="${link}" class="btn code-red btn-xs" 
              data-method='delete' 
              data-confirm="Are you sure?">
              <i class='fas fa-trash-alt'></i>
            </a>`;
        }
      }
    ]
  });
};
